import React, { useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { userPropTypes } from 'helpers/propTypes';

import Avatar from '@material-ui/core/Avatar';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Input from 'components/commons/Input';
import Button from 'components/commons/Button';
import Select from 'components/commons/Select';

import './DeliveryForm.scss';
import { getProvinces } from 'services/user.services';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Nombre inválido';
  }
  if (!values.last_name) {
    errors.last_name = 'Apellido inválido';
  }
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Dirección de email inválida';
  }
  if (!values.id_card || !/^[0-9]*$/i.test(values.id_card)) {
    errors.id_card = 'Numero inválido';
  }
  if (!values.phone || !/^(54)?[1-9]\d{9}$/i.test(values.phone)) {
    errors.phone = 'Número inválido (Ej: 541123456789)';
  }
  if (!values.locality) {
    errors.locality = 'Localidad inválida';
  }
  if (!values.province) {
    errors.province = 'Provincia inválida';
  }
  return errors;
};

const DeliveryForm = (props) => {
  const {
    handleSubmit, initialize, onSubmit, submitting, user,
    couponText, setCouponText, onSubmitCoupon, coupon, emailComplete,
    setEmailComplete, validationEmailCoupon, setEmailCoupon,
  } = props;
  const [provinces, setProvinces] = useState([]);
  const options = provinces.map((province) => ({ label: province.name, value: province.name }));

  const callProvinces = async () => {
    try {
      const responce = await getProvinces();
      setProvinces(responce.items);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    callProvinces();
    if (user) {
      initialize({ ...user });
      setEmailComplete(true);
      setEmailCoupon(user.email);
    }
    // eslint-disable-next-line
  }, [user, setEmailComplete, setEmailCoupon]);

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmitCoupon();
    }
  };

  return (
    <div className="container" data-testid="delivery-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <ShoppingCart />
          </Avatar>
          <h4>Envío a domicilio</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              component={Input}
              label="Nombre"
              name="first_name"
            />
            <Field
              component={Input}
              label="Apellido"
              name="last_name"
            />
            <Field
              component={Input}
              disabled={!!user}
              label="Email"
              onChange={(event) => validationEmailCoupon(event.target.value)}
              name="email"
            />
            <Field
              component={Input}
              label={process.env.REACT_APP_CLIENT_ID === "5" ? "CUIT" : "DNI"}
              name="id_card"
              type="number"
            />
            <Field
              component={Input}
              label="Celular / WhatsApp"
              name="phone"
              type="number"
            />
            <Field
              component={Input}
              label="Dirección y altura"
              name="address"
            />
            <div className="row">
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Codigo Postal"
                name="postal_code"
              />
              <Field
                component={Input}
                formGroupClassName="col-sm-6"
                label="Departamento"
                name="departament"
              />
            </div>
            <Field
              component={Input}
              label="Localidad"
              name="locality"
            />
            <div>
              <Field
                component={Input}
                label="Aplicar Cupon"
                name="coupon"
                disabled={!!coupon || !emailComplete}
                value={couponText}
                onChange={(event) => setCouponText(event.target.value)}
                onKeyPress={onKeyPress}
              />
              <Button
                buttonClass={!coupon ? 'btn-search-set' : 'btn-search-reset'}
                disabled={!emailComplete}
                text={
                  !coupon ? ' Aplicar Cupon ' : <i className="fa fa-trash-o" aria-hidden="true" />
                }
                type="button"
                onClick={onSubmitCoupon}
              />
            </div>
            <Field label="Provincia" name="province" component={Select} options={options} />
            <Field
              component={Input}
              label="Desea recibir novedades"
              name="suscribe"
              type="checkbox"
            />
            <Button text="Continuar" type="button" onClick={handleSubmit(onSubmit)} buttonClass="button-submit btn-block" disabled={submitting} />
          </form>
        </div>
      </div>
    </div>
  );
};

DeliveryForm.defaultProps = {
  user: null,
  coupon: null,
  couponText: '',
  emailComplete: false,
};

DeliveryForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  user: userPropTypes,
  couponText: PropTypes.string,
  setCouponText: PropTypes.func.isRequired,
  emailComplete: PropTypes.bool,
  onSubmitCoupon: PropTypes.func.isRequired,
  setEmailComplete: PropTypes.func.isRequired,
  setEmailCoupon: PropTypes.func.isRequired,
  validationEmailCoupon: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'delivery',
  validate,
})(memo(DeliveryForm));
