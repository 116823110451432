import React from 'react';
import { Link } from 'react-router-dom';

import logoImage from './assets/logo.png';
import logoImage2 from './assets/logo2.png';

import './Logo.scss';

const Logo = ({logoSrc}) => (
  <>
    <div className="logo-container" data-testid="logo">
      <Link to="/home">
        <img src={logoSrc} className="logo-image" alt="Tienda Online" />
      </Link>
    </div>
    <div className="logo-container2" data-testid="logo2">
      <Link to="/home">
        <img src={logoSrc} className="logo-image" alt="Tienda Online" />
      </Link>
    </div>
  </>
);

export default Logo;
